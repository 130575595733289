import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatAutocompleteModule,
  MatAutocompleteSelectedEvent,
} from '@angular/material/autocomplete';
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoPipe } from '@jsverse/transloco';
import { uniq } from 'lodash-es';
import { Observable, startWith, map } from 'rxjs';

@Component({
  selector: 'resto-filter-tags',
  standalone: true,
  imports: [
    FormsModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatIconModule,
    MatFormFieldModule,
    AsyncPipe,
    ReactiveFormsModule,
    TranslocoPipe,
  ],
  templateUrl: './filter-tags.component.html',
  styleUrl: './filter-tags.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterTagsComponent implements OnChanges, OnInit {
  @Input() fullWidth: boolean = false;
  @Input() initialTags!: string[] | null;
  @Input() tags!: string[] | null;
  @Output() changeSelection = new EventEmitter<string[]>();

  @ViewChild('tagInput', { static: true })
  tagInput!: ElementRef<HTMLInputElement>;

  tagCtrl = new FormControl('');
  filteredTags!: Observable<string[]>;
  selectedTags: string[] = [];
  allTags: string[] = [];
  separatorKeysCodes: number[] = [ENTER, COMMA];

  ngOnChanges(changes: SimpleChanges): void {
    if ('initialTags' in changes && changes['initialTags']) {
      this.selectedTags = changes['initialTags'].currentValue;
    }
    if ('tags' in changes && changes['tags']) {
      this.allTags = changes['tags'].currentValue;
    }
  }

  ngOnInit(): void {
    this.filteredTags = this.tagCtrl.valueChanges.pipe(
      startWith(null),
      map((tag: string | null) =>
        tag ? this._filter(tag) : this.allTags.slice(),
      ),
    );
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      this.selectedTags = Object.assign([], this.selectedTags);
      this.selectedTags.push(value);
      this.selectedTags = uniq(this.selectedTags);
      this.changeSelection.emit(this.selectedTags);
    }
    event.chipInput.clear();
    this.tagCtrl.setValue(null);
  }

  remove(tag: string): void {
    const index = this.selectedTags.indexOf(tag);

    if (index >= 0) {
      this.selectedTags = Object.assign([], this.selectedTags);
      this.selectedTags.splice(index, 1);
      this.changeSelection.emit(this.selectedTags);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.selectedTags = Object.assign([], this.selectedTags);
    this.selectedTags.push(event.option.viewValue);
    this.selectedTags = uniq(this.selectedTags);
    this.changeSelection.emit(this.selectedTags);
    this.tagInput.nativeElement.value = '';
    this.tagCtrl.setValue(null);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.allTags.filter((tag) =>
      tag.toLowerCase().includes(filterValue),
    );
  }
}
