import {
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  FormControl,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  MatCheckboxChange,
  MatCheckboxModule,
} from '@angular/material/checkbox';
import {
  MatOptionSelectionChange,
  MatOptionModule,
} from '@angular/material/core';
import { MatMenuTrigger, MatMenuModule } from '@angular/material/menu';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoService, TranslocoPipe } from '@jsverse/transloco';
import { GroupedOrder } from 'src/app/models/fired';
import {
  AddOrderParams,
  GroupBy,
  Order,
  ServiceStatus,
} from 'src/app/models/orders';
import { ReservationDetail } from 'src/app/models/reservations';
import { OrderTableBaseComponent } from 'src/app/shared/Classes/order-table-component.base';
import { calculateTotalOrders } from 'src/app/shared/utils.functions';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FiredCountDownComponent } from './fired-count-down/fired-count-down.component';
import { KeyValuePipe, NgClass } from '@angular/common';
import { OrderItemComponent } from '../../../shared/components/order-item/order-item.component';
import { FiredWaitingTimeComponent } from './fired-waiting-time/fired-waiting-time.component';
import { MatTableModule } from '@angular/material/table';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ConsumerDataItemComponent } from 'src/app/shared/components/consumer-data-item/consumer-data-item.component';

@Component({
  selector: 'resto-fired-orders-table',
  templateUrl: './fired-orders-table.component.html',
  styleUrls: [
    './fired-orders-table.component.scss',
    './../../../shared/styles/table-component.base.scss',
  ],
  standalone: true,
  imports: [
    MatTooltipModule,
    MatButtonModule,
    MatMenuModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatOptionModule,
    MatIconModule,
    MatDividerModule,
    MatTableModule,
    MatCheckboxModule,
    FiredWaitingTimeComponent,
    OrderItemComponent,
    NgClass,
    FiredCountDownComponent,
    MatProgressSpinnerModule,
    TranslocoPipe,
    KeyValuePipe,
    ConsumerDataItemComponent,
  ],
})
export class FiredOrdersTableComponent
  extends OrderTableBaseComponent
  implements OnChanges
{
  @ViewChild(MatMenuTrigger) addOrder!: MatMenuTrigger;

  @Input() clock: Date | null = null;
  @Input() firedOrders!: GroupedOrder[] | null;
  @Input() isRequireTableSeating!: boolean | null | undefined;
  @Input() sectionFilter!: string | null;
  @Input() sections!: string[] | null;
  @Input() selectedColumns!: string[] | null;
  @Input() tablesToShow!: string[] | null | undefined;

  @Output() addOrderEvent = new EventEmitter<{
    data: { service_status: ServiceStatus } & Partial<AddOrderParams>;
  }>();
  @Output() changeOrderTableEvent = new EventEmitter<{
    data: Partial<ReservationDetail>;
    callback?: boolean;
  }>();
  @Output() doneOrder = new EventEmitter<{ ids: number[] }>();
  @Output() expediteOrder = new EventEmitter<{
    ids: number[];
    selectedReason: string;
    value: boolean;
  }>();
  @Output() undoOrder = new EventEmitter<{ ids: number[] }>();
  @Output() updateComment = new EventEmitter<{
    id: number;
    comment: string;
  }>();

  allColumns = ['select', 'name', 'time', 'order', 'type', 'diets', 'profile'];
  columnsToDisplay: string[] = [];
  filteredColumns: string[] = [];
  headers = ['select', 'name', 'time', 'order'];
  dataSource!: Order[];
  dataSourceToDisplay!: Order[];
  expediteAll: boolean = false;
  orderDetail = new FormControl('', Validators.required);
  ordersBeingExpedite: number[] = [];
  selectedOrdersForExpedite: Order[] = [];
  selectedReason!: string;
  selectOrderFormControl = new FormControl('');
  selectSectionFormControl = new FormControl<string | null>(
    null,
    Validators.required,
  );
  startCountDown!: boolean;
  tableNumber = new FormControl('');
  timeDuration: number | null = null;

  constructor(
    protected override route: ActivatedRoute,
    protected override router: Router,
    protected override destroyRef: DestroyRef,
    private translationService: TranslocoService,
  ) {
    super(route, router, destroyRef);
  }

  override ngOnChanges(changes: SimpleChanges): void {
    if ('selectedColumns' in changes && this.selectedColumns) {
      const selectedColumns = this.selectedColumns.filter((column) =>
        ['type', 'diets', 'profile'].includes(column),
      );
      this.filteredColumns = [];
      this.filteredColumns = this.headers.concat(selectedColumns);
      this.columnsToDisplay = this.allColumns.filter((o) =>
        this.filteredColumns.some((j) => o === j),
      );
    }
    if (changes['firedOrders'] && this.firedOrders) {
      this.dataSource = this.firedOrders.reduce(
        (acc, { table_number, orders }) => [
          ...acc,
          { table_number, isGroupBy: true } as GroupBy,
          ...orders,
        ],
        [] as Order[],
      );
      this.selection.clear();
    }
    if (
      'sectionFilter' in changes &&
      this.selectOrderFormControl.value !== this.sectionFilter
    ) {
      this.selectOrderFormControl.setValue(this.sectionFilter, {
        emitEvent: false,
      });
    }
    if (changes && changes['lang']) this.ordering = this.lang;
    if (
      changes['tablesToShow'] ||
      (changes['firedOrders'] && this.firedOrders)
    ) {
      if (this.tablesToShow?.length) {
        this.dataSourceToDisplay = this.dataSource.filter((o) =>
          this.tablesToShow!.some(
            (tableNumber) =>
              o.table_number === tableNumber ||
              o.service_table_number === tableNumber,
          ),
        );
      } else {
        this.dataSourceToDisplay = this.dataSource;
      }
    }
  }

  isAllSelected(): boolean {
    const numSelected = this.selection.selected.reduce(function (
      previousValue,
      currentObject,
    ) {
      return previousValue + (currentObject.id ? 1 : 0);
    }, 0);
    const numRows = calculateTotalOrders(this.firedOrders);
    return numSelected === numRows;
  }

  masterToggle(): void {
    this.isAllSelected()
      ? this.selection.clear()
      : this.firedOrders?.forEach((row) =>
          row.orders.forEach((order: Order) => this.selection.select(order)),
        );
  }

  selectTableOrders(tableNumber: string, event: MatCheckboxChange): void {
    this.firedOrders?.forEach((el) => {
      if (el.table_number === tableNumber) {
        if (event.checked) {
          el.orders.forEach((order: Order) => this.selection.select(order));
        } else {
          el.orders.forEach((order: Order) => this.selection.deselect(order));
        }
      }
    });
  }

  checkTableHeader(tableNumber: string): boolean {
    let checked: boolean = false;
    let selectedTableOrder = 0;
    this.firedOrders?.forEach((el) => {
      if (el.table_number === tableNumber) {
        el.orders.forEach((order: Order) => {
          if (this.selection.isSelected(order)) {
            return (selectedTableOrder += 1);
          } else {
            return;
          }
        });
      }
    });
    this.firedOrders?.forEach((el) => {
      if (
        el.table_number === tableNumber &&
        el.orders.length === selectedTableOrder
      ) {
        checked = true;
      }
    });
    return checked;
  }

  expedite(orderId: number, ids: number[], reason: number, undo = false): void {
    if (undo) {
      this.expediteOrder.emit({ ids, selectedReason: '', value: false });
    } else {
      this.startCountDown = true;
      this.ordersBeingExpedite.push(orderId);
      this.setSelectedReason(reason);
    }
  }

  setSelectedReason(reason: number) {
    switch (reason) {
      case 1:
        this.selectedReason = this.translationService.translate(
          'fired.table.actions.expedite-reasons.early-leave',
        );
        break;
      case 2:
        this.selectedReason = this.translationService.translate(
          'fired.table.actions.expedite-reasons.medical-reason',
        );
        break;
      case 3:
        this.selectedReason = this.translationService.translate(
          'fired.table.actions.expedite-reasons.other',
        );
        break;
      default:
        break;
    }
  }

  countDownComplete(data: { id: number }): void {
    this.ordersBeingExpedite = this.ordersBeingExpedite.filter(
      (item) => item !== data.id,
    );
    this.expediteAll = false;
    this.startCountDown = false;
  }

  expediteOrderEvent(data: { ids: number[]; selectedReason: string }): void {
    if (!this.selectedOrdersForExpedite.length) {
      this.expediteOrder.emit({
        ids: data.ids,
        selectedReason: data.selectedReason,
        value: true,
      });
    }
  }

  checkForMultipleExpediteOrder(data: {
    id: number;
    selectedReason: string;
  }): void {
    if (
      this.ordersBeingExpedite.length === 0 &&
      this.selectedOrdersForExpedite.length
    ) {
      const ids: number[] =
        this.selectedOrdersForExpedite?.map((order) => order.ids).flat(1) ?? [];
      this.expediteOrder.emit({
        ids,
        selectedReason: data.selectedReason,
        value: true,
      });
      this.selectedOrdersForExpedite = [];
    }
  }

  doneAllOrders(): void {
    const ids: number[] =
      this.selection.selected?.map((order) => order.ids).flat(1) ?? [];
    this.doneOrder.emit({ ids });
    this.selection.clear();
  }

  doneAOrder(ids: number[]): void {
    this.doneOrder.emit({ ids });
  }

  multipleConsumerTableUpdate(): void {
    const selectedConsumers = this.selection.selected
      .filter((order) => !order.is_user)
      .map((order) => order.consumer);
    const selectedUsers = this.selection.selected
      .filter((order) => order.is_user)
      .map((order) => order.created_by);
    const data: Partial<ReservationDetail> = {
      consumers: selectedConsumers,
      users: selectedUsers,
    };
    if (this.isRequireTableSeating) {
      data.table_number = this.tableNumber.value ?? '';
    }
    this.changeOrderTableEvent.emit({ data });
    this.tableNumber.reset();
    this.selection.clear();
  }

  expediteAllOrders(reason: number): void {
    this.setSelectedReason(reason);
    this.selection.selected.forEach((el) => {
      if (this.ordersBeingExpedite.indexOf(el.id) === -1)
        this.ordersBeingExpedite.push(el.id);
      if (this.selectedOrdersForExpedite.indexOf(el) === -1)
        this.selectedOrdersForExpedite.push(el);
    });
    this.expediteAll = true;
    this.selection.clear();
  }

  undoSelectedOrders(): void {
    const ids: number[] =
      this.selection.selected?.map((order) => order.ids).flat(1) ?? [];
    this.undoOrder.emit({ ids });
    this.selection.clear();
  }

  addSpecialOrder(): void {
    this.selection.selected.forEach((el) => {
      if (
        this.orderDetail.value &&
        this.selectSectionFormControl.value !== null
      ) {
        const data: {
          service_status: ServiceStatus;
        } & Partial<AddOrderParams> = {
          item: this.orderDetail.value,
          item_baselang: this.orderDetail.value,
          section_sorting: el.section_sorting,
          section_level3: this.selectSectionFormControl.value,
          section_level3_baselang: this.selectSectionFormControl.value,
          service_status: 3,
        };
        el.is_user
          ? (data.created_by = el.created_by)
          : (data.consumer = el.consumer);
        this.addOrderEvent.emit({ data });
      }
    });
    this.orderDetail.reset();
    this.selectSectionFormControl.reset();
    this.selection.clear();
    this.addOrder.closeMenu();
  }

  selectSection(
    event: MatOptionSelectionChange<string> | MatOptionSelectionChange<null>,
  ): void {
    if (event && event.isUserInput) {
      this.selectSectionFormControl.patchValue(event.source.value);
    }
  }
}
