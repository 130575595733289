import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatCheckboxChange,
  MatCheckboxModule,
} from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { calculateTotalOrders } from 'src/app/shared/utils.functions';
import { GroupBy, Order } from 'src/app/models/orders';
import { GroupedOrder } from 'src/app/models/fired';
import { OrderTableBaseComponent } from 'src/app/shared/Classes/order-table-component.base';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { OrderItemComponent } from '../../../shared/components/order-item/order-item.component';
import { FiredWaitingTimeComponent } from '../../fired/fired-orders-table/fired-waiting-time/fired-waiting-time.component';
import { MatTableModule } from '@angular/material/table';
import { MatDividerModule } from '@angular/material/divider';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ConsumerDataItemComponent } from 'src/app/shared/components/consumer-data-item/consumer-data-item.component';
import { KeyValuePipe } from '@angular/common';

@Component({
  selector: 'resto-done-orders-table',
  templateUrl: './done-orders-table.component.html',
  styleUrls: [
    './done-orders-table.component.scss',
    './../../../shared/styles/table-component.base.scss',
  ],
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatOptionModule,
    MatTooltipModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatDividerModule,
    MatTableModule,
    MatCheckboxModule,
    FiredWaitingTimeComponent,
    OrderItemComponent,
    MatProgressSpinnerModule,
    TranslocoPipe,
    KeyValuePipe,
    ConsumerDataItemComponent,
  ],
})
export class DoneOrdersTableComponent
  extends OrderTableBaseComponent
  implements OnChanges
{
  @Input() doneOrders!: GroupedOrder[] | null;
  @Input() isRequireTableSeating!: boolean | null | undefined;
  @Input() selectedColumns!: string[] | null;
  @Input() sectionFilter!: string | null;
  @Input() sections!: string[] | null;
  @Input() tablesToShow!: string[] | null | undefined;

  @Output() undoOrder = new EventEmitter<{ ids: number[] }>();
  @Output() updateComment = new EventEmitter<{
    id: number;
    comment: string;
  }>();

  allColumns = ['select', 'name', 'time', 'order', 'type', 'diets', 'profile'];
  columnsToDisplay: string[] = [];
  filteredColumns: string[] = [];
  headers = ['select', 'name', 'time', 'order'];
  dataSource!: Order[];
  dataSourceToDisplay!: Order[];
  selectOrderFormControl = new FormControl('');

  override ngOnChanges(changes: SimpleChanges): void {
    if ('selectedColumns' in changes && this.selectedColumns) {
      const selectedColumns = this.selectedColumns.filter((column) =>
        ['type', 'diets', 'profile'].includes(column),
      );
      this.filteredColumns = [];
      this.filteredColumns = this.headers.concat(selectedColumns);
      this.columnsToDisplay = this.allColumns.filter((o) =>
        this.filteredColumns.some((j) => o === j),
      );
    }
    if (changes['doneOrders'] && this.doneOrders) {
      this.dataSource = this.doneOrders.reduce(
        (acc, { table_number, orders }) => [
          ...acc,
          { table_number, isGroupBy: true } as GroupBy,
          ...orders,
        ],
        [] as Order[],
      );
      this.selection.clear();
    }
    if (
      'sectionFilter' in changes &&
      this.selectOrderFormControl.value !== this.sectionFilter
    ) {
      this.selectOrderFormControl.setValue(this.sectionFilter, {
        emitEvent: false,
      });
    }
    if (changes && changes['lang']) this.ordering = this.lang;
    if (changes['tablesToShow'] || (changes['doneOrders'] && this.doneOrders)) {
      if (this.tablesToShow?.length) {
        this.dataSourceToDisplay = this.dataSource.filter((o) =>
          this.tablesToShow!.some(
            (tableNumber) =>
              o.table_number === tableNumber ||
              o.service_table_number === tableNumber,
          ),
        );
      } else {
        this.dataSourceToDisplay = this.dataSource;
      }
    }
  }
  isAllSelected(): boolean {
    const numSelected = this.selection.selected.reduce(function (
      previousValue,
      currentObject,
    ) {
      return previousValue + (currentObject.id ? 1 : 0);
    }, 0);
    const numRows = calculateTotalOrders(this.doneOrders);
    return numSelected === numRows;
  }

  masterToggle(): void {
    this.isAllSelected()
      ? this.selection.clear()
      : this.doneOrders?.forEach((row) =>
          row.orders.forEach((order: Order) => this.selection.select(order)),
        );
  }

  selectTableOrders(tableNumber: string, event: MatCheckboxChange): void {
    this.doneOrders?.forEach((el) => {
      if (el.table_number === tableNumber) {
        if (event.checked) {
          el.orders.forEach((order: Order) => this.selection.select(order));
        } else {
          el.orders.forEach((order: Order) => this.selection.deselect(order));
        }
      }
    });
  }

  checkTableHeader(tableNumber: string): boolean {
    let checked: boolean = false;
    let selectedTableOrder = 0;
    this.doneOrders?.forEach((el) => {
      if (el.table_number === tableNumber) {
        el.orders.forEach((order: Order) => {
          if (this.selection.isSelected(order)) {
            return (selectedTableOrder += 1);
          } else {
            return;
          }
        });
      }
    });
    this.doneOrders?.forEach((el) => {
      if (
        el.table_number === tableNumber &&
        el.orders.length === selectedTableOrder
      ) {
        checked = true;
      }
    });
    return checked;
  }

  undoSelectedOrders(): void {
    const ids: number[] =
      this.selection.selected?.map((order) => order.ids).flat(1) ?? [];
    this.undoOrder.emit({ ids });
    this.selection.clear();
  }
}
