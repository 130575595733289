import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  FormControl,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { Consumer } from 'src/app/models/user';
import { ReservationDetail } from 'src/app/models/reservations';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgClass } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
  selector: 'resto-reservations-rfid',
  templateUrl: './reservations-rfid.component.html',
  styleUrls: ['./reservations-rfid.component.scss'],
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    NgClass,
    MatTooltipModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    TranslocoPipe,
  ],
})
export class ReservationsRfidComponent implements OnChanges {
  @Input() isRequireTableSeating!: boolean | null | undefined;
  @Input() isRfidDetailsLoading!: boolean | null;
  @Input() ordertakingTableFeature!: boolean | null;
  @Input() prefillLastUsedTable!: boolean | null | undefined;
  @Input() prefillSavedTable!: boolean | null | undefined;
  @Input() previousTableNumber!: string | null;
  @Input() rfidDetails!: Consumer | null;
  @Input() rfidDetailsCount!: number | null;
  @Input() showThankYouMessage!: boolean;
  @Output() setPreviousTableNumberEvent = new EventEmitter<{
    tableNumber: string;
  }>();

  @Output() changeReservationStatusEvent = new EventEmitter<{
    data: Partial<ReservationDetail>;
    callback: boolean;
    change: boolean;
  }>();
  @Output() clearRfidFieldEvent = new EventEmitter<void>();
  @Output() fetchRfidDetailEvent = new EventEmitter<{ rfid: string }>();
  @Output() patchConsumer = new EventEmitter<{
    url: string;
    data: Partial<Consumer>;
  }>();

  rfid = new FormControl('', Validators.required);
  tableNumber = new FormControl('');

  ngOnChanges(changes: SimpleChanges): void {
    if ('rfidDetails' in changes && this.rfidDetails) {
      if (!this.isRequireTableSeating) {
        if (this.ordertakingTableFeature && this.prefillSavedTable) {
          this.tableNumber.patchValue(this.rfidDetails.table_number);
        }
        this.updateServiceStatus();
      } else {
        if (
          this.rfidDetails.service_status &&
          this.rfidDetails.service_status_table
        ) {
          this.tableNumber.patchValue(this.rfidDetails.service_status_table);
        } else if (this.ordertakingTableFeature && this.prefillSavedTable) {
          this.tableNumber.patchValue(this.rfidDetails.table_number);
        } else if (
          this.isRequireTableSeating &&
          this.prefillLastUsedTable &&
          this.previousTableNumber
        ) {
          this.tableNumber.patchValue(this.previousTableNumber);
        }
      }
    }
  }

  cancelRfidInput(): void {
    this.clearRfidFieldEvent.emit();
    this.rfid.reset();
  }

  fetchRfidDetail(): void {
    if (this.rfid.value)
      this.fetchRfidDetailEvent.emit({ rfid: this.rfid.value });
  }

  updateServiceStatus(): void {
    if (!this.rfidDetails) return;
    const data: Partial<ReservationDetail> = {
      consumers: [],
      users: [],
      table_number: this.tableNumber.value ?? '',
    };

    // set consumers or users
    this.rfidDetails.is_user
      ? data.users!.push(this.rfidDetails.id)
      : data.consumers!.push(this.rfidDetails.id);

    // set previous table number to new value
    if (this.isRequireTableSeating) {
      this.setPreviousTableNumberEvent.emit({
        tableNumber: this.tableNumber.value ?? '',
      });
    }

    // submit event and reset the field
    this.changeReservationStatusEvent.emit({
      data,
      change: !!this.rfidDetails?.service_status,
      callback: true,
    });
    this.tableNumber.reset();
    this.rfid.reset();
  }

  saveTable(consumer: Consumer) {
    this.patchConsumer.emit({
      url: consumer.url,
      data: {
        table_number: consumer.service_status_table,
      },
    });
  }
}
