import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatCheckboxChange,
  MatCheckboxModule,
} from '@angular/material/checkbox';
import { SelectionModel } from '@angular/cdk/collections';
import { ConsumerParams, ReservationDetail } from 'src/app/models/reservations';
import { Consumer } from 'src/app/models/user';
import { TableBaseComponent } from 'src/app/shared/Classes/table-component.base';
import { TranslocoPipe } from '@jsverse/transloco';
import { ReservationsRfidComponent } from '../reservations-rfid/reservations-rfid.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { ConsumerDataItemComponent } from '../../../shared/components/consumer-data-item/consumer-data-item.component';
import { NgClass, KeyValuePipe, JsonPipe, AsyncPipe } from '@angular/common';
import { SortButtonComponent } from '../../../global/sort-button/sort-button.component';
import { MatTableModule } from '@angular/material/table';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDividerModule } from '@angular/material/divider';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { FilterTagsComponent } from 'src/app/shared/components/filter-tags/filter-tags.component';

@Component({
  selector: 'resto-reservations-table',
  templateUrl: './reservations-table.component.html',
  styleUrls: [
    './../../../shared/styles/table-component.base.scss',
    './reservations-table.component.scss',
  ],
  standalone: true,
  imports: [
    MatButtonToggleModule,
    MatIconModule,
    MatCheckboxModule,
    FormsModule,
    MatTooltipModule,
    MatButtonModule,
    MatMenuModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatDividerModule,
    MatProgressSpinnerModule,
    MatTableModule,
    SortButtonComponent,
    NgClass,
    ConsumerDataItemComponent,
    MatPaginatorModule,
    ReservationsRfidComponent,
    KeyValuePipe,
    TranslocoPipe,
    JsonPipe,
    FilterTagsComponent,
  ],
})
export class ReservationsTableComponent
  extends TableBaseComponent
  implements OnChanges
{
  @Input() applyTableFilterReservations: boolean | null | undefined = true;
  @Input() hideCheckedinReservations: boolean | null | undefined = false;
  @Input() prefillSavedTable!: boolean | null | undefined;
  @Input() isRequireTableSeating!: boolean | null | undefined;
  @Input() isRfidDetailsLoading!: boolean | null;
  @Input() prefillLastUsedTable!: boolean | null | undefined;
  @Input() previousTableNumber!: string | null;
  @Input() reservationConsumers!: Consumer[] | null;
  @Input() ordertakingTableFeature!: boolean | null;
  @Input() rfidDetails!: Consumer | null;
  @Input() rfidDetailsCount!: number | null;
  @Input() selectedColumns!: string[] | null;
  @Input() showThankYouMessage!: boolean;
  @Input() tables!: string[] | null;

  @Output() changeReservationStatusEvent = new EventEmitter<{
    data: Partial<ReservationDetail>;
    callback?: boolean;
    change: boolean;
  }>();
  @Output() clearReservationsEvent = new EventEmitter<{
    data: Partial<ReservationDetail>;
  }>();
  @Output() clearRfidFieldEvent = new EventEmitter<void>();
  @Output() deleteReservationsEvent = new EventEmitter<{
    data: Partial<ReservationDetail>;
  }>();
  @Output() fetchRfidDetailEvent = new EventEmitter<{ rfid: string }>();
  @Output() override applyParams!: EventEmitter<Partial<ConsumerParams>>;
  @Output() setPreviousTableNumberEvent = new EventEmitter<{
    tableNumber: string;
  }>();
  @Output() patchConsumer = new EventEmitter<{
    url: string;
    data: Partial<Consumer>;
  }>();

  allColumns = [
    'select',
    'name',
    'room',
    'reservation_status',
    'table',
    'type',
    'diets',
    'profile',
  ];
  columnsToDisplay: string[] = [];
  currentConsumer!: Consumer;
  filterCheckinOnly = false;
  filteredColumns: string[] = [];
  headers = ['select', 'reservation_status', 'table'];
  reservations: Consumer[] = [];
  selectedViewFilter = 1;
  selection = new SelectionModel<Consumer>(true, []);
  tableFilter: string[] = [];
  tableNumber = new FormControl('');

  override ngOnChanges(changes: SimpleChanges): void {
    if ('reservationConsumers' in changes && this.reservationConsumers) {
      this.reservations = this.reservationConsumers;
    }
    if ('selectedColumns' in changes && this.selectedColumns) {
      this.filteredColumns = [];
      this.filteredColumns = this.headers.concat(this.selectedColumns);
      this.columnsToDisplay = this.allColumns.filter((o) =>
        this.filteredColumns.some((j) => o === j),
      );
    }
    if (
      this.previousTableNumber &&
      (changes['prefillSavedTable'] ||
        changes['prefillLastUsedTable'] ||
        changes['previousTableNumber'])
    ) {
      if (!this.prefillLastUsedTable) {
        this.tableNumber.patchValue(this.previousTableNumber);
      } else {
        this.tableNumber.reset();
      }
    }
    if (changes && changes['lang']) this.ordering = this.lang;
  }

  filterTables(tables: string[]): void {
    this.tableFilter = tables;
    if (this.reservationConsumers) {
      this.reservations = tables.length
        ? this.reservationConsumers.filter((cons) =>
            tables.includes(
              cons.service_status_table
                ? cons.service_status_table
                : cons.table_number,
            ),
          )
        : this.reservationConsumers;
      this.isAllSelected();
    }
  }

  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.reservations?.length;
    return numSelected === numRows;
  }

  setViewFilter(id: number): void {
    this.selectedViewFilter = id;
  }

  masterToggle(): void {
    this.isAllSelected()
      ? this.selection.clear()
      : this.reservations?.forEach((row) => this.selection.select(row));
  }

  getClassName(serviceStatus: boolean, vip: boolean): string {
    if (vip) {
      return serviceStatus ? 'vip-checked-service' : 'vip-not-checked-service';
    } else {
      return serviceStatus ? 'checked-service' : 'not-checked-service';
    }
  }

  setCurrentConsumer(data: Consumer): void {
    // set current consumer
    this.tableNumber.reset();
    this.currentConsumer = data;

    // prefill table number
    if (data.service_status && data.service_status_table) {
      this.tableNumber.patchValue(data.service_status_table);
    } else if (this.ordertakingTableFeature && this.prefillSavedTable) {
      this.tableNumber.patchValue(this.currentConsumer.table_number);
    } else if (
      this.isRequireTableSeating &&
      this.prefillLastUsedTable &&
      this.previousTableNumber
    ) {
      this.tableNumber.patchValue(this.previousTableNumber);
    }

    // autosubmit if not yet seated and table seating is not required
    if (!data.service_status && !this.isRequireTableSeating) {
      this.changeReservationStatus();
    }
  }

  changeReservationStatus(): void {
    if (!this.currentConsumer) return;
    const data: Partial<ReservationDetail> = {
      consumers: [],
      users: [],
      table_number: this.tableNumber.value ?? '',
    };

    // set previous table number to new value
    if (this.isRequireTableSeating) {
      this.setPreviousTableNumberEvent.emit({
        tableNumber: data.table_number ?? '',
      });
    }

    // set consumers or users
    this.currentConsumer.is_user
      ? data.users!.push(this.currentConsumer.id)
      : data.consumers!.push(this.currentConsumer.id);

    // submit event and reset the field
    this.changeReservationStatusEvent.emit({
      data,
      change: !!this.currentConsumer.service_status,
    });
    this.tableNumber.reset();
  }

  changeMultipleReservationStatus(): void {
    const selectedConsumers = this.selection.selected
      .filter(
        (consumer: Consumer) => !consumer.is_user && !consumer.service_status,
      )
      .map((consumer: Consumer) => consumer.id);
    const selectedUsers = this.selection.selected
      .filter(
        (consumer: Consumer) => consumer.is_user && !consumer.service_status,
      )
      .map((consumer: Consumer) => consumer.id);

    const selectedConsumersChange = this.selection.selected
      .filter(
        (consumer: Consumer) => !consumer.is_user && !!consumer.service_status,
      )
      .map((consumer: Consumer) => consumer.id);
    const selectedUsersChange = this.selection.selected
      .filter(
        (consumer: Consumer) => consumer.is_user && !!consumer.service_status,
      )
      .map((consumer: Consumer) => consumer.id);

    const data: Partial<ReservationDetail> = {
      consumers: selectedConsumers,
      users: selectedUsers,
    };
    const dataChange: Partial<ReservationDetail> = {
      consumers: selectedConsumersChange,
      users: selectedUsersChange,
    };
    if (this.isRequireTableSeating) {
      data.table_number = this.tableNumber.value ?? '';
      dataChange.table_number = this.tableNumber.value ?? '';
      this.setPreviousTableNumberEvent.emit({
        tableNumber: this.tableNumber.value ?? '',
      });
    }
    if (data.consumers?.length || data.users?.length)
      this.changeReservationStatusEvent.emit({ data, change: false });
    if (dataChange.consumers?.length || dataChange.users?.length)
      this.changeReservationStatusEvent.emit({
        data: dataChange,
        change: true,
      });
    this.tableNumber.reset();
    this.selection.clear();
  }

  deleteSelectedReservations(): void {
    const selectedConsumers = this.selection.selected
      .filter((consumer: Consumer) => !consumer.is_user)
      .map((consumer: Consumer) => consumer.id);
    const selectedUsers = this.selection.selected
      .filter((consumer: Consumer) => consumer.is_user)
      .map((consumer: Consumer) => consumer.id);
    const data: Partial<ReservationDetail> = {
      consumers: selectedConsumers,
      users: selectedUsers,
      delete_orders: true,
    };
    this.deleteReservationsEvent.emit({ data });
    this.selection.clear();
  }

  clearReservation(): void {
    const data: Partial<ReservationDetail> = {
      consumers: [],
      users: [],
    };
    this.currentConsumer.is_user
      ? data.users!.push(this.currentConsumer.id)
      : data.consumers!.push(this.currentConsumer.id);
    this.clearReservationsEvent.emit({ data });
  }

  hideCheckedInConsumers(event: MatCheckboxChange): void {
    this.filterCheckinOnly = event.checked;
    const params = event.checked
      ? {
          has_service_status: !event.checked,
        }
      : {};
    this.applyParams.emit(params);
  }

  getIconClassName(serviceStatus: boolean, vip: boolean): string {
    if (vip) {
      return serviceStatus
        ? 'checked-service-icon'
        : 'not-checked-service-icon-vip';
    } else {
      return serviceStatus
        ? 'checked-service-icon'
        : 'not-checked-service-icon';
    }
  }

  saveTable(consumer: Consumer) {
    this.patchConsumer.emit({
      url: consumer.url,
      data: {
        table_number: consumer.service_status_table,
      },
    });
  }
}
