import { MatButtonModule } from '@angular/material/button';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoPipe } from '@jsverse/transloco';
import { DescriptionDialogComponent } from '../description-dialog/description-dialog.component';

@Component({
  selector: 'resto-order-item',
  templateUrl: './order-item.component.html',
  styleUrls: ['./order-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatButtonModule, MatIconModule, TranslocoPipe],
})
export class OrderItemComponent implements OnChanges {
  @Input() consistency!: (1 | 2 | 3 | 4 | null)[];
  @Input() description!: string[];
  @Input() diets: string[] = [];
  @Input() item!: string[];
  @Input() itemOption!: number;
  @Input() portionSize!: number[];
  @Input() quantity!: number[];
  @Input() texture!: (1 | 2 | 3 | 4 | 5 | null)[];
  @Input() variants!: string[];

  @Output() updateComment = new EventEmitter<string>();

  sameConsistency = false;
  samePortionSize = false;
  sameTexture = false;

  constructor(private dialog: MatDialog) {}

  ngOnChanges(changes: SimpleChanges) {
    if ('portionSize' in changes && this.portionSize) {
      this.samePortionSize = this.allEqual(this.portionSize);
    }
    if ('consistency' in changes && this.consistency) {
      this.sameConsistency = this.allEqual(this.consistency);
    }
    if ('texture' in changes && this.texture) {
      this.sameTexture = this.allEqual(this.texture);
    }
  }

  allEqual = (arr: (string | boolean | number | null)[]): boolean =>
    arr.every((v) => v === arr[0]);

  openCommentDialog(): void {
    this.dialog.open(DescriptionDialogComponent, {
      data: {
        description: this.description.slice(-1)[0],
        save: (value: string) => this.updateComment.emit(value),
      },
      width: '450px',
      maxWidth: '95vw',
    });
  }
}
