import { Component } from '@angular/core';
import { TranslocoPipe } from '@jsverse/transloco';

@Component({
  selector: 'resto-access-forbidden',
  templateUrl: './access-forbidden.component.html',
  styleUrls: ['./access-forbidden.component.scss'],
  standalone: true,
  imports: [TranslocoPipe],
})
export class AccessForbiddenComponent {}
